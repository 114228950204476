<template>
  <div class="message">
    <div v-if="list.length > 0">
      <van-swipe-cell
        style="
          margin: 15px 0;
          background-color: white;
          padding: 10px 0 10px 10px;
          border-radius: 6px;
        "
        v-for="(item, index) in list"
        :key="index"
      >
        <div style="height: 40px; line-height: 20px">
          <div class="flexLR" style="height: 100%">
            <div
              class="flexLR"
              style="width: 90%; justify-content: left; height: 100%"
            >
              <img src="../../../assets/mobileImg/项目通知.png" alt="" />
              <div
                style="
                  height: 100%;
                  width: 100%;
                  align-items: flex-start;
                  margin-left: 10px;
                "
                class="flexcolumn"
              >
                <div class="nowrap" style="width: 100%">
                  {{ item.Msg }}
                </div>
                <div style="color: #a2a2a2">发布时间：{{ item.Time }}</div>
              </div>
            </div>
          </div>
        </div>
        <template #right>
          <van-button
            size="small"
            style="height: 100%"
            square
            v-if="item.IsAgree==null"
            text="同意"
            type="info"
            @click="consentBtn(item)"
            class="delete-button"
          />
          <van-button
            size="small"
            style="height: 100%"
            v-if="item.IsAgree==null"
            square
            @click="refuseBtn(item)"
            text="拒绝"
            type="warning"
            class="delete-button"
          />
          <van-button
            size="small"
            @click="deleteBtn(item)"
            style="height: 100%"
            square
            text="删除"
            type="danger"
            class="delete-button"
          />
        </template>
      </van-swipe-cell>
    </div>
    <div v-else style="color: #0a9efc; margin-top: 100px; text-align: center">
      <img src="../../../assets/mobileImg/wu.png" width="60" alt="" />
      <div>暂无数据</div>
    </div>
  </div>
</template>

<script>
import { HandleMsg, DelMsg, GetMessage } from "@/api";
export default {
  name: "message",
  components: {},
  data() {
    return {
      list: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    updateData() {
      this.getData();
      this.$emit("updateLoading", "消息");
    },
    async consentBtn(v) {
      let res = await HandleMsg({
        id: v.Id,
        type: v.Type,
        state: 1,
      });
      this.$CodeMessage(res);
      this.getData();
    },
    async refuseBtn(v) {
      let res = await HandleMsg({
        id: v.Id,
        type: v.Type,
        state: 2,
      });
      this.$CodeMessage(res);
      this.getData();
    },
    async deleteBtn(v) {
      let res = await DelMsg({
        id: v.Id,
        type: v.Type,
      });
      this.$CodeMessage(res, "操作成功");
      this.getData();
    },
    async getData() {
      let res = await GetMessage();
      if (res.Code > 0) this.list = res.Data;
    },
  },
  created() {
    // this.list = $route.params.list;
    this.getData();
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.message {
  margin: 0 auto;
  padding: 0 40px;
}
</style>